import {Page} from "../components/page";
import {Service} from "../components/service";

const serviceOptions = [
    {
        option_title: <h2>Payment solutions</h2>,
        services: [
            {
                title: <h3><span/>Subscription business model</h3>,
                shortText: <div>Spend time running your business rather than worrying if your customers stay signed up.</div>,
                fullText:
                    <>
                        <div>Spend time running your business rather than worrying if your customers stay signed up.</div>
                        <div>Consider using the subscription business model. When users attach a bank card, you should give them value and monitor subscription statuses. You'll have sales regularly once you get customers to pay for subscriptions. This guarantees growth.</div>
                        <div>Get detailed information about your subscriptions with an ML algorithm that helps you reach your best LTV and predict your cash flow.</div>
                    </>,
            },
            {
                title: <h3><span/>One-click payments</h3>,
                shortText: <div>Enable secure and convenient payments on your website using Ganga Pay's token solution.</div>,
                fullText:
                    <>
                        <div>Enable secure and convenient payments on your website using Ganga Pay's token solution.</div>
                        <div>This tool allows businesses to increase conversion rates and provide a better checkout experience on any device. Once the customer has entered their payment information during their initial purchase, subsequent purchases can be completed with a single click, utilising the stored data.</div>
                        <div>One-click payment methods can be integrated into various platforms, including e-commerce websites, mobile apps, and digital wallets.</div>
                    </>
            },
            {
                title: <h3><span/>Alternative payments</h3>,
                shortText: <div>Provide alternative payments that exist outside traditional cash, credit cards, or bank transfers.</div>,
                fullText:
                    <div>
                        Provide alternative payments that exist outside traditional cash, credit cards, or bank transfers. Ganga Pay usually integrates them to offer alternative ways to pay, often catering to specific regions, demographics, or technological advancements. These methods provide flexibility and broaden businesses customer base.<br/>
                        Among alternative payment methods, you can choose:
                        <ul>
                            <li>Mobile Wallets</li>
                            <li>P2P Payments</li>
                            <li>BNPL Method</li>
                            <li>Prepaid cards</li>
                            <li>Etc.</li>
                        </ul>
                    </div>
            }
        ]
    },
    {
        option_title: <h2><span>Tele</span><span>communications</span></h2>,
        services: [
            {
                title: <h3><span/>Messaging Applications</h3>,
                shortText: <div>Create platforms and applications that facilitate text and multimedia, catering to various communication needs.</div>,
                fullText:
                    <div>
                        <p>Create platforms and applications that facilitate text and multimedia, catering to various communication needs.</p>
                        <p>Create brand-new messaging applications with pivoting features for different audiences. Make a bias towards security, payment, entertainment options or specific Gen Z needs. Our skilled developers will implement your most significant ideas.</p>
                        <p>Contact us for more information about our experience in communication app development.</p>
                    </div>,
            },
            {
                title: <h3><span/>Network Infrastructure Software</h3>,
                shortText: <div>Implement security measures within telecommunication systems to protect data and ensure privacy.</div>,
                fullText:
                    <div>
                        <p>Implement security measures within telecommunication systems to protect data and ensure privacy.</p>
                        <p>Thousands of users chat and call each other daily and expect providers to be secure. Network infrastructure software is critical in ensuring a network's reliability, efficiency, and security.</p>
                        <p>Do not hesitate to contact us. Our team provides ready-to-use systems for our clients.</p>
                    </div>
            },
            {
                title: <h3><span/>UI/UX Design</h3>,
                shortText: <div>Get the intuitive interface design for voice and video calls, chats, support pages, and more. Keep the user engaged.</div>,
                fullText:
                    <div>
                        <p>Get the intuitive interface design for voice and video calls, chats, support pages, and more. Keep the user engaged.</p>
                        <p>Good UI/UX design guarantees that the client will engage with the product. Designing a website or application is not only about the outside but also about the inside. In other words, it is about navigation, signals, accessibility, etc.</p>
                    </div>
            }
        ]
    },
]

export const OurServices = () => {
    return (
        <Page services>
            <section className="our-services container s">
                <h1>Our Services</h1>
                <div className="our-services-list white-blur-bg">
                    {serviceOptions.map(({option_title, services}, index) => (
                        <div className='service-option'>
                            {option_title}
                            <div className="services-list">
                                {services.map((service) => (
                                    <Service key={index.toString()} service={service}/>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </Page>
    )
}