import arrowRight from '../../assets/arrow-right.svg';

export const FirstScreen = () => (
    <section className="first-screen">
        <div className="container">
            <h1>Ganga Pay</h1>
            <div className="first-screen-content">
                <div>Crafting communication <br/> through code. </div>
                <div>Creating perfect <br/>payment experience.</div>
                <button>
                    <img src={arrowRight} alt="arrow-right"/>
                    <span>Get started</span>
                </button>
            </div>
        </div>
    </section>
);
