import {Link} from "react-router-dom";
import facebookIcon from "../assets/facebook.svg";
import instagramIcon from "../assets/instagram.svg";
import twitterIcon from "../assets/twitter.svg";
import linkedinIcon from "../assets/linkedIn.svg";

export const Footer = () => (
    <footer>
        <div className="container s">
            <div className="white-blur-bg">
                <div className="navigation">
                    <nav>
                        <Link to={'/'}>Home</Link>
                        <Link to={'/our-services'}>Our Services</Link>
                        <Link to={'/contact-us'}>Contact us</Link>
                    </nav>
                    <div className='social-links'>
                        <a href="https://www.facebook.com/profile.php?id=100093369920110" target='_blank' rel="noreferrer"><img src={facebookIcon} alt="facebook-link"/></a>
                        <a href="https://www.instagram.com/ganga.pay/" target='_blank' rel="noreferrer"><img src={instagramIcon} alt="instagram-link"/></a>
                        <a href="https://twitter.com/GangaPay" target='_blank' rel="noreferrer"><img src={twitterIcon} alt="twitter-link"/></a>
                        <a href="https://www.linkedin.com/company/ganga-pay/" target='_blank' rel="noreferrer"><img src={linkedinIcon} alt="linkedin-link"/></a>
                    </div>
                </div>
                <div className="copyright">
                    <span>2023 Relume. All right reserved.</span>
                </div>
            </div>
        </div>
    </footer>
)
