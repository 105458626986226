import {Page} from "../components/page";

export const ContactUs = () => {
    return (
        <Page contactUs>
            <section className="contact-us container s">
                <h1>Contact us</h1>
                <div className="white-blur-bg">
                    <h2>Get a free consultation!</h2>
                    <div className="info-part">
                        <h3>Email:</h3>
                        <p><a href="mailto:contact@gangapay.com">contact@gangapay.com</a></p>
                    </div>
                    <div className="info-part">
                        <h3>Feel free to write to us on Linkedin:</h3>
                        <button
                            className="white-stroke"
                            onClick={() => window.open('https://www.linkedin.com/company/ganga-pay/', '_blank').focus()}
                        >Ganga Pay Linkedin</button>
                    </div>
                    <div className="info-part">
                        <h3>Address:</h3>
                        <p>71-75 Shelton Street</p>
                        <p>Covent Garden</p>
                        <p>London</p>
                        <p>WC2H 9JQ</p>
                        <p>UNITED KINGDOM</p>
                    </div>
                </div>
            </section>
        </Page>
    )
}
