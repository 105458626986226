import {Page} from "../components/page";
import {FirstScreen} from "../sections/home-sections/first-screen";
import {About} from "../sections/home-sections/about";
import {Services} from "../sections/home-sections/services";
import {StrongPoints} from "../sections/home-sections/strong-points";

export const Home = () => (
    <Page homePage>
        <FirstScreen/>
        <About/>
        <Services/>
        <StrongPoints/>
    </Page>
)
