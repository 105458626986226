export const Services = () => (
    <section className="container s services">
        <h2>Our services</h2>
        <div className="white-blur-bg">
            <div className="service-section">
                <h3 className="service-section-title">
                    Payment solutions
                </h3>
                <div className="service-section-list">
                    <div className="service">
                        <h4>Subscription <br/> business model</h4>
                        <p>Spend time running your business rather than worrying if your customers stay signed up.</p>
                    </div>
                    <div className="service">
                        <h4>One-click <br/> payments</h4>
                        <p>Enable secure and convenient payments on your website using Ganga Pay's token solution.</p>
                    </div>
                    <div className="service">
                        <h4>Alternative <br/> payments</h4>
                        <p>Provide alternative payments that exist outside traditional cash, credit cards, or bank transfers.</p>
                    </div>
                </div>
            </div>
            <div className="service-section">
                <h3 className="service-section-title">
                    Telecommunications
                </h3>
                <div className="service-section-list">
                    <div className="service">
                        <h4>Messaging <br/> Applications</h4>
                        <p>Create platforms and applications that facilitate text and multimedia, catering to various communication needs.</p>
                    </div>
                    <div className="service">
                        <h4>Network Infrastructure Software</h4>
                        <p>Implement security measures within telecommunication systems to protect data and ensure privacy.</p>
                    </div>
                    <div className="service">
                        <h4>UI/UX <br/> Design</h4>
                        <p>Get the intuitive interface design for voice and video calls, chats, support pages, etc. Keep the user engaged.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)